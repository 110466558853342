import React from 'react';
import styled from 'styled-components';

import Headline02 from '../components/typography/Headline02';
import Body02 from '../components/typography/Body02';
import Body01 from '../components/typography/Body01';
import { emailAddress } from '../constants';

const PrivacyPolicy = () => {
  return (
    <Root>
      <Title>Privacy Policy</Title>
      <Text>
        reaction.menu is committed to protecting your privacy and handling your personal data responsibly. 
        This Privacy Policy explains how we collect, use, disclose, and protect your personal information when you visit our website.

      </Text>
      <Text>
        By using our website or services, you agree to this privacy policy and other posted terms. If you do not agree, please discontinue use of our services.
      </Text>

      <Subtitle bold>
      1. Information we collect
      </Subtitle>

      <Text>
        We collect the following types of information:
        <ul>
            <li>
                Personal Information: When you create account, subscribe to our services, or contact us, we may collect your name, email address, payment details, and other relevant information.
            </li>
            <li>
                Usage Data: We collect information about how you use our Services, such as pages visited, interactions, and preferences.
            </li>
            <li>
                Device Information: We may collect information about your device, including IP address, browser type, and operating system.
            </li>
            <li>
                Cookies and Tracking Technologies: We use cookies and similar technologies to improve user experience and analyze usage patterns.
            </li>
        </ul>
      </Text>

      <Subtitle bold>
        2. How we use your information
      </Subtitle>

      <Text>
        We use your information for the following purposes:
        <ul>
            <li>
                Provide and maintain our Services         
            </li>
            <li>
                Improve and personalize user experience
            </li>
            <li>
                Send updates, security notifications, and customer support responses
            </li>
            <li>
                Comply with legal obligations and enforce our Terms of Service
            </li>
        </ul>
      </Text>

      <Subtitle bold>
        3. How we share your information
      </Subtitle>

      <Text>
        We do not sell or rent your personal information. However, we may share your information with:
        <ul>
            <li>
                Service Providers: We may share data with third-party vendors who assist in operating our Services (e.g., payment processors, cloud storage, analytics providers).
            </li>
            <li>
                Legal Compliance: We may disclose your information to comply with legal requirements, enforce policies, or respond to lawful requests.
            </li>
        </ul>
      </Text>

      <Subtitle bold>
        4. Data Retention
      </Subtitle>

      <Text>
        We retain personal data for as long as necessary to fulfill the purposes outlined in this policy, comply with legal obligations, resolve disputes, and enforce agreements.        
      </Text>

      <Subtitle bold>
        5. Your Rights
      </Subtitle>

      <Text>
        If you are located in the European Economic Area, in accordance with European Union data protection regulations, you have certain rights with respect to your personal data.
        You have the right to request access to your personal data and to correct, amend, delete, or limit the use of your personal data by reaching us at contact@reaction.menu. Furthermore, if you believe that we have unlawfully processed your personal data, you have the right to submit a complaint to the contact information provided below, or to your respective data protection supervisory authority.
        To exercise your rights, contact us at contact@reaction.menu
      </Text>

      <Subtitle bold>
        6. Security measure
      </Subtitle>

      <Text>
      We implement industry-standard security measures to protect your data. However, no method of transmission over the internet is completely secure, and we cannot guarantee absolute security. Therefore we cannot guarantee absolute security of information. We encourage you to take care of the personal data in your possession that you process online and set strong passwords for your reaction.menu account, limit access of your computer and browser by signing off after you have finished your session, and avoid providing us with any sensitive information whose disclosure you believe could cause you substantial harm.
      </Text>

      <Subtitle bold>
        7. Cookies and tracking technologies
      </Subtitle>

      <Text>
        We use cookies to enhance user experience. You can manage cookie preferences through your browser settings.
      </Text>

      <Subtitle bold>
        8. Third-party links
      </Subtitle>

      <Text>
        Our Services may contain links to third-party websites. We are not responsible for their privacy practices and encourage you to review their policies.
      </Text>

      <Subtitle bold>
        9. Changes to this policy
      </Subtitle>

      <Text>
        We may update this Privacy Policy periodically, for example in cases when we introduce new services or new features.
        Changes will be posted on this page with an updated effective date. The amendments to this Privacy Policy enter into force and are applied from the moment they have been uploaded to this page.
        Therefore, we encourage you to check this page from time to time. By continuing to use our Services or otherwise providing personal data to us, after the amendments to this policy have been implemented, you agree to the updated terms of Privacy Policy.
      </Text>

      <Subtitle bold>
        10. Contact Us
      </Subtitle>

      <Text>
        If you have any questions or concerns about this Privacy Policy, please contact us at <a href={emailAddress} target="_blank" rel="noreferrer">contact@reaction.menu</a>
      </Text>

    <Text bold>
        Effective date: 21 Feb 2025
    </Text>
    </Root>
  );
};

export default PrivacyPolicy;

export const Root = styled.div`
    width: 100%;
    position: relative;
    padding: 0 16px;
    margin: 80px 0;

     @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        padding: 0 40px;
    }
`;

export const Text = styled(Body02)`
    margin-bottom: 24px;
`;

export const Subtitle = styled(Body01)`
    padding: 24px 0 12px;
`;

export const Title = styled(Headline02)`
    margin-bottom: 8px;
`;