import styled, { useTheme } from 'styled-components';
import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

import "swiper/css";
import '../styles/swiper.css';
import "swiper/css/autoplay";

import Body02 from '../components/typography/Body02';
import Caption from '../components/typography/Caption';
import Headline03 from '../components/typography/Headline03';
import { kostasUrl, donsUrl, mostraUrl, aretiUrl, bistrotUrl, lokalUrl } from '../constants';

import local from '../img/reviews/1.png';
import kostas from '../img/reviews/2.png';
import dons from '../img/reviews/3.png';
import bistro from '../img/reviews/4.png';
import areti from '../img/reviews/5.png';
import mostra from '../img/reviews/6.png';

const Testimonials = () => {
    const theme = useTheme();

    return (
        <Root> 
            <Caption center>Our users 🤩</Caption>
            <StyledHeadline03 center>
                <Span>
                    Bars and restaurants that already use
                </Span>
                <span>
                    reaction.menu to centralize their online presence
                </span>
            </StyledHeadline03>

            <Swiper
                spaceBetween={ 32 }
                slidesPerView={"auto"}
                centeredSlides={ true }
                modules={[Autoplay]}
                autoplay={{
                    delay: 0, 
                    disableOnInteraction: true, 
                    pauseOnMouseEnter: false, 
                  }}
                  speed={8000} 
                loop={ true }
                followFinger={ true }
                grabCursor={ true }

            >
                <Review>
                    <Wrapper rotate='2deg'>
                        <Link href="https://www.facebook.com/share/18ZLXZAs5w/?mibextid=wwXIfr" target="blank">
                            @paliakaisimera
                        </Link>
                    
                        <Img src={ kostas } alt="reaction.menu" />
                    
                        <Link href={ kostasUrl } target="blank">
                            View menu
                        </Link>
                    </Wrapper>
                </Review>  

                <Menu>
                    <Wrapper rotate='0deg'>
                        <Body02 color={ theme.textSecondary}>
                            Areti Tavern
                        </Body02>

                        <Img src={ areti } alt="reaction.menu" />
                    
                        <Link href={ aretiUrl } target="blank">
                            View menu
                        </Link>
                    </Wrapper>
                </Menu> 
                
                <Review>
                    <Wrapper rotate='-2deg'>
                        <Link href="https://www.facebook.com/share/166vsHRSny/?mibextid=wwXIfr" target="blank">
                            @lokal.limassol
                        </Link>
                    
                        <Img src={ local } alt="reaction.menu" />
                    
                        <Link href={ lokalUrl } target="blank">
                            View menu
                        </Link>
                    </Wrapper>
                </Review>  

                <Menu>
                    <Wrapper rotate='0deg'>
                        <Body02 color={ theme.textSecondary}>
                            Mostra Meze Tavern
                        </Body02>

                        <Img src={ mostra } alt="reaction.menu" />
                    
                        <Link href={ mostraUrl } target="blank">
                            View menu
                        </Link>
                    </Wrapper>
                </Menu> 

                <Review>
                    <Wrapper rotate='-2deg'>
                        <Link href="https://www.facebook.com/share/1A3ura9fKH/?mibextid=wwXIfr" target="blank">
                            @donssocietycafecy
                        </Link>
                    
                        <Img src={ dons } alt="reaction.menu" />
                    
                        <Link href={ donsUrl } target="blank">
                            View menu
                        </Link>
                    </Wrapper>
                </Review> 

                <Image>
                    <Wrapper rotate='0deg'>
                        <Body02 color={ theme.textSecondary}>
                            1943 Bistrot
                        </Body02>

                        <Img src={ bistro } alt="reaction.menu" />
                    
                        <Link href={ bistrotUrl } target="blank">
                            View menu
                        </Link>
                    </Wrapper>
                </Image> 
 
            </Swiper>
        </Root>
    );
};

export default Testimonials;

const Root = styled.div`
    width: 100%;
    position: relative;
    margin-bottom: 80px;

    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        margin-bottom: 120px;
    }
`;

const Review = styled(SwiperSlide)`
    width: 320px;
`;

const Image = styled(SwiperSlide)`
    width: 500px;
`;

const Menu = styled(SwiperSlide)`
    width: 360px;
`;

const Wrapper = styled.div`
    padding-top: 24px;
    transform: rotate(${ ({ rotate }) => rotate });
`;

const Link = styled.a`
   font-family: regular;
    padding: 0 12px;
    text-decoration: underline;
`;

const Img = styled.img`
   margin: 2px 0;
`;

const StyledHeadline03 = styled(Headline03)`
    margin-bottom: 16px;
`;

const Span = styled.span`
    display: inline;
    margin-right: 7px;

    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        display: block;
    };
`;

