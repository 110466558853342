import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from './styles/global';
import { theme } from './styles/theme';
import Main from './pages/Main';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Header from '../src/components/Header'
import ScrollToTop from '../src/components/ScrollToTop';
import Footer from './pages/Footer';
import TermsOfService from './pages/TermsOfService';

const App = () => {
	return (
			<Router>
				<ScrollToTop />
				
				<ThemeProvider theme={ theme }>
				
				<GlobalStyle />
			

				<Header />

        		<Routes>
				<Route path="/privacy-policy" element={<PrivacyPolicy />} />
				<Route path="/terms-of-service" element={<TermsOfService />} />
				<Route path="/" element={<Main />} />
				
        		</Routes>
				<Footer />
				</ThemeProvider>
    		</Router>
	);
}

export default App;
