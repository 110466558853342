import styled, { useTheme } from 'styled-components';
import React from 'react';

import { emailAddress } from '../constants';

import { ReactComponent as EmailIcon } from '../img/mail.svg';
import { ReactComponent as PhoneIcon } from '../img/call.svg';
import { ReactComponent as WhIcon } from '../img/whatsapp.svg';
import { ReactComponent as TgIcon } from '../img/tg.svg';
import { ReactComponent as InstaIcon } from '../img/instagram.svg';

import Body03 from '../components/typography/Body03';
import Headline03 from '../components/typography/Headline03';
import Caption from '../components/typography/Caption';
import Button from '../components/Button';

const Footer = () => {
    const theme = useTheme();

    return (
        <Root id="contacts"> 
            <Caption center>
                   Let's talk 🙂 
            </Caption>

            <DesktopHeadline03 center>
                Have questions or need assistance?<br />We’d love to hear from you!
            </DesktopHeadline03>

            <MobileHeadline03 center>
                Have questions or need assistance? We’d love to hear from you!
            </MobileHeadline03>

            <Lists>
                <List>
                    <Button label="Email" 
                        blank
                        to={ emailAddress }
                        background='transparent'
                        color={ theme.text }
                        border={ theme.border }
                        subLabel="contact@reaction.menu"
                        icon={<EmailIcon />}
                    />
                    <Button label="Phone" 
                        blank
                        to="tel:+35799881812"
                        background='transparent'
                        color={ theme.text }
                        border={ theme.border }
                        subLabel="+35799881812"
                        icon={<PhoneIcon />}

                    />

                    <Button label="WhatsApp" 
                        blank
                        to="https://wa.me/35799881812"
                        background='transparent'
                        color={ theme.text }
                        border={ theme.border }
                        subLabel="+35799881812"
                        icon={<WhIcon />}
                    />
                </List>

                <List>
                    <Button label="Telegram" 
                        blank
                        to="https://t.me/annazrva"
                        background='transparent'
                        color={ theme.text }
                        border={ theme.border }
                        subLabel="@annazrva"
                        icon={<TgIcon />}
                    />

                    <Button label="Instagram" 
                        blank
                        to="http://instagramm.com/reactionmenu"
                        background='transparent'
                        color={ theme.text }
                        border={ theme.border }
                        subLabel="reactionmenu"
                        icon={<InstaIcon />}
                    />
                </List>
            </Lists>
                
            <StyledBody03 center color={ theme.textSecondary }>
                    We speak Greek, English & Russian
            </StyledBody03>
        </Root>
    );
};

export default Footer;

const Root = styled.div`
    width: 100%;
    position: relative;
    padding: 0 16px;
    margin: 120px 0 32px;

    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
    }
`;

const Lists = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
`;

const List = styled.div`
    display: flex;
    gap: 16px;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;
    flex-wrap: wrap;

    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        flex-direction: row;
        gap: 20px;
        justify-content: center;
    }
`;

const StyledBody03 = styled(Body03)`
    margin-top: 20px;
`;

const DesktopHeadline03 = styled(Headline03)`
    margin: 0 0 16px;
    display: none;

     @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        display: block;
    }
`;

const MobileHeadline03 = styled(Headline03)`
    display: block;
    margin: 0 0 16px;

    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        display: none;
    }
`;
