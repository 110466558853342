import styled, { useTheme } from 'styled-components';
import React from 'react';

import { signupUrl } from '../constants';
import Body02 from '../components/typography/Body02';
import Caption from '../components/typography/Caption';
import Headline01 from '../components/typography/Headline01';
import Headline02 from '../components/typography/Headline02';
import Headline03 from '../components/typography/Headline03';
import Body03 from '../components/typography/Body03';

const Prices = () => {
    const theme = useTheme();

    return (
        <Root> 
            <Caption center >
                   How much does it cost? 🤓
            </Caption>

               <StyledHeadline03 center >
                   Take your business<br />to the next level
               </StyledHeadline03>

               <Headline01 center regular >
                   €29/mo
               </Headline01>

               <StyledBody02 center color={ theme.textSecondary }>
                    Get 2 months free when billed<br />annually - <span>€290/year</span>
               </StyledBody02>

                <Link href={ signupUrl }
                    target="_blank"
                >
                    <StyledHeadline02 center>Try for free</StyledHeadline02>
                </Link>

                  <StyledBody03 color={ theme.textSecondary}>No card required</StyledBody03>
        </Root>
    );
};

export default Prices;

const Root = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 16px;
    margin-bottom: 80px;


    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        padding: 0 40px;
        margin-bottom: 120px;

    }
`;

const StyledHeadline03 = styled(Headline03)`
    margin: 0 0 16px;
`;

const StyledBody02 = styled(Body02)`
    margin-bottom: 24px;
`;

const StyledBody03 = styled(Body03)`
    margin-top: 16px;
`;

const StyledHeadline02 = styled(Headline02)`
    background: ${ ({ theme }) => theme.primary };
    padding: 30px 0px;
    border-radius: 132px;
    white-space: nowrap;

    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        padding: 40px 80px;
    }
`;

const Link = styled.a`
    width: 100%;
    text-decoration: none;

    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        width: auto;
    }
`;
