import styled, { useTheme } from 'styled-components';
import React from 'react';
import { Link } from 'react-router-dom';

import Body03 from '../components/typography/Body03';

const Footer = () => {
    const theme = useTheme();

    return (
        <Root> 
            <Hooks>
                    <Body03 color={ theme.textSecondary }>
                        © { new Date().getFullYear() } reaction.menu 
                    </Body03>

                    <Body03 color={ theme.textSecondary }>
                        Built with ❤️ in Limassol, Cyprus                    
                    </Body03>

                    <Links>
                        <Body03 color={ theme.textSecondary }>
                            <Link to="/privacy-policy">
                                Privacy Policy
                            </Link>
                        </Body03>

                        <Body03 color={ theme.textSecondary }>
                        <Link to="/terms-of-service">
                                Terms
                            </Link>
                        </Body03>
                    </Links>
             </Hooks>
        </Root>
    );
};

export default Footer;

const Root = styled.div`
    width: 100%;
    position: relative;
    padding: 0 16px;
    margin: 120px 0 32px;

    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
    }
`;

const Hooks = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
    align-items: center;
    justify-content: space-between;
    margin-top: 80px;
    color: ${ ({ theme }) => theme.textSecondary });

    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        flex-direction: row;
    }
`;

const Links = styled.div`
    display: flex;
    gap: 16px;
`;
