import styled from 'styled-components';

const Body03 = styled.p`
    color: ${ ({ theme, color }) =>
        color ? color
        : theme.text };
    font-size: 16px;
    font-family: regular;
    letter-spacing: 0.25px;
    text-align: ${ ({ center }) =>
    center ? 'center'
    : '' };
    line-height: 23px;
    font-family: ${ ({ bold }) =>
    bold ? 'bold, Arial'
    : 'regular, Arial' };

    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
       
    }
`;

export default Body03;