import React, { useLayoutEffect } from 'react';
import styled from 'styled-components';

import About from './About';
import FirstPage from './FirstPage';
import Signup from '../components/Signup';
import Prices from './Prices';
import Features from './Features';
import Testimonials from './Testimonials';
import HowTo from './HowTo';
import Contacts from './Contacts';

const Main = () => {
    useLayoutEffect(() => {
        const hash = window.location.hash;
        const contactElement = document.getElementById('contacts');
        
        if (hash === '#contacts' && contactElement) {
            contactElement.scrollIntoView();
        }
    }, []);

    return (
        <Root>
            <Signup />
            <Container>
                <FirstPage />
                <HowTo />
                <Features />
                <Testimonials />
                <Prices />
                <About />
                <Contacts />
            </Container>
        </Root>
    );
};

export default Main;

const Root = styled.div`
    position: relative;
`;

const Container = styled.div`
    
`;
