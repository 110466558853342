import React from 'react';

import { emailAddress } from '../constants';
import { Root, Text, Subtitle, Title } from './PrivacyPolicy';

const TermsOfService = () => {
  return (
    <Root>
      <Title>Terms of Service</Title>
      <Text>
        Welcome to reaction.menu! 
        These Terms of Service ("Terms") govern your use of the reaction.menu website and services ("Service"). 
        By accessing or using our Service, you agree to comply with these Terms. 
        If you do not agree with any part of these Terms, please do not use our Service.
      </Text>

      <Subtitle bold>
      1. Eligibility
      </Subtitle>

      <Text>
        You must be at least 18 years old or have parental consent to use the Service. 
        By using the Service, you confirm that you have the legal capacity to enter into this agreement.
      </Text>

      <Subtitle bold>
        2. Accounts and Responsibilities
      </Subtitle>

      <Text>
        <ul>
            <li>
                You must provide accurate and complete information when creating an account.
            </li>
            <li>
                You are responsible for safeguarding your account credentials and any activity under your account.
            </li>
            <li>
                You agree not to share your account details with others.
            </li>
            <li>
                We reserve the right to terminate or suspend your account if we suspect a violation of these Terms.
            </li>
        </ul>
      </Text>

      <Subtitle bold>
        3. Use of the Service
      </Subtitle>

      <Text>
        <ul>
            <li>
                You may not use the Service for any illegal, harmful, or abusive activities.
            </li>
            <li>
                You agree not to copy, modify, distribute, or exploit any part of the Service without our written consent.
            </li>
            <li>
                We reserve the right to modify, suspend, or discontinue the Service at any time. We will provide reasonable notice where possible before making significant changes.
            </li>
        </ul>
      </Text>

      <Subtitle bold>
        4. Intellectual Property
      </Subtitle>

      <Text>
        All content, trademarks, and intellectual property on the Service remain the property of reaction.menu or its licensors. You may not reproduce, distribute, or create derivative works without permission.
      </Text>

      <Subtitle bold>
        5. User Content
      </Subtitle>

      <Text>
        <ul>
            <li>
                You retain ownership of any content you submit, but grant us a non-exclusive license to use, modify, and display it as necessary to provide the Service.           
            </li>
            <li>
                You are responsible for ensuring your content does not infringe on third-party rights.           
            </li>
            <li>
                We reserve the right to remove any content that violates these Terms or is deemed inappropriate.
            </li>
        </ul>
      </Text>

      <Subtitle bold>
        6. Privacy and Data Protection
      </Subtitle>

      <Text>
        <ul>
            <li>
                We process personal data in accordance with our Privacy Policy and applicable laws.           
            </li>
            <li>
                You acknowledge and agree that data may be stored and processed within the European Union or other jurisdictions as necessary for the functionality of the Service.
            </li>
        </ul>      
    </Text>

      <Subtitle bold>
        7. Subscriptions and Payments
      </Subtitle>

      <Text>
        <ul>
            <li>
                Some features of the Service require a subscription, which will be billed on a recurring basis. Billing cycles are set on a monthly or yearly basis.
                At the end of each Billing Cycle, your Subscription will automatically renew under the exact same conditions unless you cancel it or reaction.menu cancels it. 
                You may cancel your Subscription renewal either through your subscription management page or by contacting us.            
            </li>
            <li>
                You must provide a valid payment method, and your subscription will renew automatically unless canceled.
            </li>
            <li>
                No refunds are issued for partially used subscription periods.
            </li>
        </ul>  
      </Text>

      <Subtitle bold>
        8. Third-Party Links and Services
      </Subtitle>

      <Text>
        Our Service may contain links to third-party websites or services. 
        We are not responsible for their content, policies, or practices. 
        Your use of third-party services is at your own risk.
      </Text>

    <Subtitle bold>
        9. Limitation of Liability
    </Subtitle>

    <Text>
      You agree that we shall not be liable for any damages suffered as a result of using the Service, copying, distributing, or downloading Content from the Service.
    </Text>
    <Text>
      In no event shall we be liable for any indirect, punitive, special, incidental or consequential damage (including loss of business, revenue, profits, use, privacy, data, goodwill or other economic advantage) however it arises, whether for breach of contract or in tort, even if it has been previously advised of the possibility of such damage.
    </Text>
    <Text>
      You have sole responsibility for adequate security protection and backup of data and/or equipment used in connection with your usage of the Service and will not make a claim against for lost data, re-run time, inaccurate instruction, work delays or lost profits resulting from the use of the Service. You must not assign or otherwise dispose of your account to any other person.
    </Text>
    <Text>
      Our total liability shall not exceed the amount you paid for the Service in the last 12 months.
    </Text>   

     <Subtitle bold>
        9. Disclaimer
    </Subtitle>

    <Text>
    Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE" basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.    
    </Text>
    <Text>
    reaction.menu its subsidiaries, affiliates, and its licensors do not warrant that a) the Service will function uninterrupted, secure or available at any particular time or location; b) any errors or defects will be corrected; c) the Service is free of viruses or other harmful components; or d) the results of using the Service will meet your requirements.   
    </Text>
    <Text>
    This disclaimer of liability applies to any damages or injury caused by any failure of performance, error, omission, interruption, deletion, defect, delay in operation or transmission, computer virus, communication line failure, theft, or destruction or unauthorized access or, alteration of or use of record in connection with the use or operation of the Service, whether for breach of contract, tortious behavior, negligence or any other cause of action.    
    </Text>
    <Text>
    We make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the content contained on the Service for any purpose. Any reliance you place on such information is therefore strictly at your own risk. We disclaim any express or implied warranty representation or guarantee as to the effectiveness or profitability of the Service or that the operation of our Service will be uninterrupted or error-free. We are not liable for the consequences of any interruptions or error in the Service.    
    </Text>       

    <Subtitle bold>
        10. Exclusions
    </Subtitle>

    <Text>
      Some jurisdictions do not allow the exclusion of certain warranties or the exclusion or limitation of liability for consequential or incidental damages, so the limitations above may not apply to you.
    </Text>

    <Subtitle bold>
        11. Changes to These Terms
    </Subtitle>

    <Text>
        We reserve the right to update these Terms at any time. Continued use of the Service after any changes constitutes your acceptance of the new Terms.
    </Text>
    <Text>
    It is your sole responsibility to periodically check these Terms for any changes. 
    If you do not agree with any of the changes to these Terms, it is your sole responsibility to stop using the Service. 
    Your continued use of the Service will be deemed as your acceptance thereof.
    </Text>

    <Subtitle bold>
        12. Governing Law
    </Subtitle>

    <Text>
        These Terms shall be governed by and interpreted in accordance with the laws of the European Union.
    </Text>

      <Subtitle bold>
        10. Contact Us
      </Subtitle>

      <Text>
        If you have any questions or concerns about these Terms, please contact us at <a href={emailAddress} target="_blank" rel="noreferrer">contact@reaction.menu</a>
      </Text>

    <Text bold>
        Last updated: 21 Feb 2025
    </Text>
    </Root>
  );
};

export default TermsOfService;