import styled from 'styled-components';
import React from 'react';

import Body02 from '../components/typography/Body02';
import Caption from '../components/typography/Caption';

const Footer = () => {
    return (
        <Root> 
            <Caption center >
                About 😊
            </Caption>

            <StyledBody02 center>
                We are Anna & Petros Makrigiyannis, the team behind reaction.menu.
                With over 10 years of experience in creation of digital products (Anna's <a href="https://www.linkedin.com/in/annazayarova/" target='_blank' rel='noreferrer'>LinkedIn</a>) and strong background in hospitality (Petros's <a href="https://www.lemongrasshostel.com" target='_blank' rel='noreferrer'>Lemongrass hostel</a>),
                we've created an easy to use and intuitive digital menu solution.
                Our mission is to make dining out a smoother, more enjoyable experience for everyone.
               </StyledBody02>



        </Root>
    );
};

export default Footer;

const Root = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 16px;
    margin-bottom: 80px;

    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        padding: 0 40px;
        margin-bottom: 120px;

    }
`;

const StyledBody02 = styled(Body02)`
    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        max-width: 760px;
    }
`;