import styled, { useTheme } from 'styled-components';
import React from 'react';

import Body02 from './typography/Body02';

const ListItem = ({
    item
}) => {
    const theme = useTheme();

    return (        
        <Root>
            <Bullet />

            <Body02 color={ theme.textSecondary }>
                { item }
            </Body02>
        </Root>
    );
};

export default ListItem;

const Root = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

export const Bullet = styled.div`
    width: 6px;
    height: 6px;
    content: '';
    border-radius: 32px;
    background: ${ ({ theme }) => theme.textSecondary };
`;
