import React from 'react';
import styled, { useTheme } from 'styled-components';

import Body01 from '../components/typography/Body01';
import Body02 from '../components/typography/Body02';

const HowTo = () => {
    const theme = useTheme();

    const scrollToContacts = (e) => {
        e.preventDefault();
        const contactsSection = document.getElementById('contacts');
        if (contactsSection) {
            contactsSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <Root>  
            <Block>
                <Grid5>
                    <Col>
                        <Body01 bold>
                            1. Sign up
                        </Body01>
                    
                        <Body02 color={ theme.textSecondary }>
                            Create your account
                        </Body02>
                    </Col>
                    <Col>
                        <Body01 bold>
                            2. Add logo, links & menu items
                        </Body01>
                    
                        <Body02 color={ theme.textSecondary }>
                            or <a 
                                href="#contacts" 
                                onClick={scrollToContacts}>
                                    send us</a> your social links and menu, we'll handle the setup for you — completely free
                        </Body02>
                    </Col>

                    <Col>
                        <Body01 bold>
                            3. Activate your menu
                        </Body01>
                    
                        <Body02 color={ theme.textSecondary }>
                            When yor are ready, subscribe with 14-day free trial
                        </Body02>
                    </Col>

                    <Col>
                        <Body01 bold>
                            4. Share your menu anywhere – instantly
                        </Body01>
                    
                        <Body02 color={ theme.textSecondary }>
                            Add your menu link to social media & Google maps so guests can explore it before they arrive 
                        </Body02>
                    </Col>

                    <Col>
                        <Body01 bold>
                            5. Print the QR code & place it on tables
                        </Body01>
                    
                        <Body02 color={ theme.textSecondary }>
                            <a href="#contacts" onClick={scrollToContacts}>reach out</a> if you need a custom QR code design, we will cover it for you — free of charge                  
                        </Body02>
                    </Col>

                    <Col>
                        <Body01 bold>
                            6. Guests scan the QR code & enjoy
                        </Body01>
                    
                        <Body02 color={ theme.textSecondary }>
                            One QR сode for your menu & all essential links
                        </Body02>
                    </Col>
                </Grid5>
            </Block>
        </Root>
    )
};

export default HowTo;

const Root = styled.div`    
width: 100%;
padding: 0 16px;

    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        padding: 0 40px;
    }
`;

const Block = styled.div`
    margin: 32px 0 40px;
    
`;

const Grid5 = styled.div`
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;

    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    };
`;

const Col = styled.div`
    display: flex;
    gap: 4px;
    flex-direction: column;

    @media(min-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        gap: 8px;
    };
`;